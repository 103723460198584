<style>
td{
	padding: 5px;
}
</style>
<script>
	import {sessionFetchJson} from './data_access.js';
	import {getCurrentPosition, distance} from './geo.js';
	import * as imgw_stations from './imgw_stations.js';
	import { nearestStationsMeteoStore } from './stores.js';
	import { format } from 'date-fns'

	const dtFormat = 'MM-dd HH:mm';

	let settings = {stationsRadiusInKm : 10, tz : 'Europe/Warsaw'};
	let stationsMeteo = [];
	let currentPos = undefined;

	let nearestStationsMeteo;
	const nearestStationsMeteoUnsubscribe = nearestStationsMeteoStore.subscribe(value => {
		nearestStationsMeteo = value;
	});
	

	async function loadStationsInfo() {
		stationsMeteo = await imgw_stations.loadAllStations();
	}	

	async function downloadMeasurements(stations){
		for(const station of stations){
			await imgw_stations.downloadMeasurements(station);			
		}		
	}

	async function run(){		
		currentPos = await getCurrentPosition();				
		
		await loadStationsInfo();				
		
		let stations = imgw_stations.findNearestStations(stationsMeteo, currentPos, settings.stationsRadiusInKm);

		await downloadMeasurements(stations);

		nearestStationsMeteoStore.set(stations);
	}
	
	;(async () => {
    	await run();
	})();
</script>

<h1>venov</h1>
{#if currentPos}
<p>Current position: <strong>{currentPos.coords.latitude}, {currentPos.coords.longitude}</strong> [+- {currentPos.coords.accuracy}m].</p>
{/if}
{#if nearestStationsMeteo && nearestStationsMeteo.length > 0}
<p>Found {nearestStationsMeteo.length} stations in neighborhood with {settings.stationsRadiusInKm}km radius from total {stationsMeteo.length} meteo stations.</p>
<table>
	<thead>
		<tr>
			<th scope="col">stat</th>
			<th scope="col">temp</th>
			<th scope="col">wind</th>			
		</tr>
	</thead>
	<tbody>
	{#each nearestStationsMeteo as station}
	<tr>
		<th scope="row">{station.name} ({station.distance}km)</th>
		<td><strong>{station.temperature_last.val}</strong>°C<br /> 
		{format(station.temperature_last.dt, dtFormat)}
		</td>
		<td><strong>{station.wind_last.vel}</strong>m/s, <strong>{station.wind_last.dir}</strong>°<br />
		{format(station.wind_last.dt, dtFormat)}
		 </td>
	</tr>
	{/each}
	</tbody>
</table>
{/if}