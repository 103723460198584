import {sessionFetchJson} from './data_access.js';
import {distance} from './geo.js';
import { parse } from 'date-fns'
import {utcToZonedTime } from 'date-fns-tz'

const dtFromat = "yyyy-MM-dd'T'HH:mm:ss'Z'";

let baseApiUrl = 'https://cors-anywhere.herokuapp.com/http://monitor.pogodynka.pl/api/';

async function loadAllStations() {
    return await sessionFetchJson("stations-meteo", `${baseApiUrl}map/?category=meteo`);
}

function findNearestStations(stationsMeteo, currentPos, maxDistanceInKm){
    let result = [];
    if(stationsMeteo.length > 0 && currentPos){			
        stationsMeteo.forEach(station => {
            let distanceToStation = distance(station.la, station.lo, currentPos.coords.latitude, currentPos.coords.longitude, "K");
            if(distanceToStation <= maxDistanceInKm)
            {					
                result.push({
                    id: station.i,
                    name: station.n,
                    latitude: station.la,
                    longitude: station.lo,
                    distance: distanceToStation.toFixed(1),
                    type: "im",

                    temperature_last: {},
                    wind_last: {}
                });
            }
        });			
    }
    return result.sort((a, b) => a.distance - b.distance);
}

function downloadMeasurements(station){
    return new Promise((resolve, reject) => {
        fetch(`${baseApiUrl}station/meteo/?id=${station.id}`)
        .then((response) => {				
            return response.json();
        })
        .then((jsonResponse) => {
            if(jsonResponse.temperatureObsRecords && jsonResponse.temperatureObsRecords.length > 0){
                let lastRec = jsonResponse.temperatureObsRecords[jsonResponse.temperatureObsRecords.length -1];
                station.temperature_last = {val: lastRec.value, dt: parseDate(lastRec.date), rem: 'obs' };
            }                
            if(jsonResponse.windVelocityObsRecords && jsonResponse.windVelocityObsRecords.length > 0
                && jsonResponse.windDirectionObsRecords && jsonResponse.windDirectionObsRecords.length > 0){

                    let lastRecVel = jsonResponse.windVelocityObsRecords[jsonResponse.windVelocityObsRecords.length -1];
                    let lastRecDir = jsonResponse.windDirectionObsRecords[jsonResponse.windDirectionObsRecords.length -1];
                    station.wind_last = {vel: lastRecVel.value, dir: lastRecDir.value,  dt: parseDate(lastRecVel.date), rem: 'obs' };
            }
            
            resolve();
        });
    });
}

function parseDate(dt){
    return utcToZonedTime(parse(dt, dtFromat, new Date()));
}


export { loadAllStations, findNearestStations, downloadMeasurements };