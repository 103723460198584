function sessionFetchJson(sessionKey, url){
    return new Promise((resolve, reject) => {
        var elementAsString = sessionStorage.getItem(sessionKey);

        if (elementAsString) {
            resolve(JSON.parse(elementAsString));
        }else{
            fetch(url)
            .then((response) => {				
                return response.json();
            })
            .then((jsonResponse) => {									
                sessionStorage.setItem(sessionKey, JSON.stringify(jsonResponse));
                resolve(jsonResponse);
            }).catch((error) => {
                reject(error);
            });				
        }	
    });
}

export { sessionFetchJson };